import React, { useEffect, useState } from "react";
import ReferralDashboard from "@/components/organisms/referal/referral-dashboard";
import CollapsibleCard from "@/components/atomics/collapsible-card";
import { useOwner } from "@/context/OwnerContext";
import { useWallet } from "@/context/WalletContext";
import { useParams } from "react-router-dom";

export default function OwnerReferral() {
    const { address } = useParams();
    const { walletAddress } = useWallet();
    const { isEditView } = useOwner();
    const [showOwnerView, setShowOwnerView] = useState(false);

    useEffect(() => {
        if (walletAddress === address) {
            setShowOwnerView(true);
        }
    }, [walletAddress, address]);

    return (
        (isEditView || showOwnerView) && (
            <CollapsibleCard
                editTitle="MY REFERRALS"
                viewTitle="MY REFERRALS"
                className="w-full px-[30px] text-[30px]"
                viewClassName="text-[30px]"
                isEditView={isEditView || showOwnerView}
            >
                <ReferralDashboard
                    className="w-full px-[30px] pt-[25px]"
                    isEditView={isEditView || showOwnerView}
                    walletAddress={walletAddress}
                />
            </CollapsibleCard>
        )
    )
}