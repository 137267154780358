export interface CollectionFileData {
  name: string;
  description: string;
  social_links: string[];
  imageUrl: string;
  backgroundUrl: string;
}

export interface CollectionDataCache {
  owner: string;
  address: string;
  mint_limit: number;
  price: number;
  next_item_index?: number;
  next_item_address?: string;
  data_url: string;
  item_data_url?: string;
}

export interface OwnerData {
  collections: OwnerCollectionData[],
  items:OwnerNftData[]
}

export interface OwnerCollectionData {
  address: string;
  data_url: string;
  id :Number;
  item_data_url: string;
  mint_limit: number;
  next_item_index: number;
  owner_id: number;
  price: number;
  saved_at: string;
}
export interface OwnerNftData {
  address: string;
  collection: string;
  data_url: string;
  index_in_collection: string;
   minter: string;
}

export interface Attribute {
  trait_type: string;
  value: string;
}

export interface ItemFileData {
  name?: string;
  description?: string;
  marketplace?: string;
  image: string;
  attributes: Attribute[];
}

export interface CollectionMintEntry {
  collection: CollectionFileData;
  item: ItemFileData;
  collectionAddress?: string;
}

export interface CollectionDataUrls {
  collectionFileUrl: string;
  itemFileUrl: string;
}

export interface CollectionStandardData {
  name: string;
  description: string;
  image: string;
  cover_image: string;
  social_links: string[];
}

export interface CollectionTotalData {
  standardData: CollectionStandardData;
  nextItemIndex: number;
  nextItemAddress: string;
}

export interface CollectionMasterStandardData {
  mint_limit: number;
  price: number;
  index_in_collection: number;
  master: string;
}

export interface MasterStandardData {
  master: string;
  collection_creation_price: number;
  next_collection_index: number;
}


export async function fetchData(dataUrl: string): Promise<any | null> {
  try {
      const response = await fetch(dataUrl);
      const data = await response.json();
      return data;
  } catch (error) {
      console.error('Error fetching data:', error);
      return null;
  }
}
