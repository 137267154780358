import * as React from "react";

interface  ApplyButtonType  {
    className: string;
    image: string;
    text: string;
    isLoading?: boolean;
}

export default function ApplyButton({ className, image, text, disabled, onClick, isLoading }: ApplyButtonType & { disabled?: boolean, onClick?: () => void }) {
    return (
        <div 
            className={`${className} relative ${disabled || isLoading ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
            onClick={disabled || isLoading ? undefined : onClick}
        >
            <img    
                src={image} 
                alt="button-image" 
                className="w-full h-full object-contain"
            />
            <p className="absolute top-0 w-full h-full flex items-center justify-center gap-[10px]">
                {text}
                <img src="/assets/apply-form.svg" className="w-[25px] h-[25px]" />
                {isLoading && (
                    <span className="inline-block animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-white"></span>
                )}
            </p>
        </div>
    )
} 