import ApplyButton from "@/components/atomics/apply-button";
import Button from "@/components/atomics/button";
import CopyToClipboard from "@/components/atomics/copytoClipboard";
import Statistics from "@/components/molecules/referal/statistics";
import { backend } from "@/services/backend";
import React, { useEffect, useState } from "react";

interface ReferralDashboardProps {
    className?: string;
    isEditView?: boolean;
    walletAddress: string | null | undefined;
  }

  interface ReferralStats {
    referrals: number;
    collections: number;
    total_earned: number;
  }
  export default function ReferralDashboard({ className, walletAddress , isEditView}: ReferralDashboardProps) {
    const [referralStats, setReferralStats] = useState<ReferralStats | null>(null);
    useEffect(() => {
        if (!walletAddress) return;
        const fetchReferralStats = async () => {
          const stats = await backend.getReferralStats(walletAddress!);
          setReferralStats(stats);
        };
        fetchReferralStats();
      }, [walletAddress]);
    return (
        <div className={`${className}`}>
            <div>
                <div className={`text-[32px] flex  gap-[10px] pb-[5px]`}>
                    <span className={`${isEditView ? 'text-[25px]' : 'text-[32px]'}`}>MY REF. LINK</span>
                    <span className="text-[#0099FE] text-[28px] hover:cursor-pointer">?</span>
                </div>
                <CopyToClipboard 
                    className={`pt-[10px] pb-[10px]`}  
                    length={7}
                    text={`https://app.simplenft.io/?ref=${walletAddress}` || ''}  
                />
            </div>
            <div className="pb-[20px]">
                <div className={`text-[32px] flex  gap-[10px] pb-[5px]`}>
                    <span className={`${isEditView ? 'text-[25px]' : 'text-[32px]'}`}>PARTNER LINK</span>
                    <span className="text-[#0099FE] text-[28px] hover:cursor-pointer">?</span>
                </div>
                <CopyToClipboard 
                    className={`pt-[10px] pb-[10px] pointer-events-none opacity-40`}  
                    length={7}
                    text={`https://app.simplenft.io/?ref=${walletAddress}` || ''} 
                />
            </div>
            <div className="pr-[20px] pl-[20px] flex items-center">
                <ApplyButton 
                    className={`w-full text-[26px]`}  
                    image="/assets/button.svg"
                    text="APPLY FORM" 
                />
            </div>

            <Statistics 
                className={`pt-[20px]`}
                referralCount={referralStats?.referrals || 0}
                collectionsCreated={referralStats?.collections || 0}
                nftMinted={0}
                referralsCashback={0}
                tonsCollected={0}
            />
            <div className="relative flex items-center justify-center pt-[35px] pb-[48px] gap-[10px]">
                <div className="pr-[20px] pl-[20px]">
                    <Button 
                        className={`w-full text-[24px] text-[#0A0A0A]`}  
                        image="/assets/disabled-button.svg"
                        text={`CLAIM ${referralStats?.total_earned} TON`} 
                    />
                </div>
                <span className="absolute right-[-10px] text-[#0099FE] text-[28px] hover:cursor-pointer">?</span>
            </div>
     </div>
    );
  }
