import * as React from "react";
import { useEffect, useState } from "react";
import { fetchData } from "@/types";
import Infinity from "@/components/atomics/infinity";
interface  CollectionCardType {
    onClick?: () => void;
    className: string,
    data_url?: string,
    value?: number
    totalMintCount?: number
    currentMintedCount?: number
}

export default function CollectionCard({className, data_url, value, totalMintCount, currentMintedCount, onClick }: CollectionCardType) {
    const [collectionData, setCollectionData] = useState<any | null>(null);
    useEffect(() => {
        if (data_url) {
            fetchData(data_url).then((data) => {
                setCollectionData(data);
            });
        }
    }, [data_url])
    
    const formatName = (name: string) => {
        if (name.length <= 9) return name;
        return `${name.slice(0, 4)}...${name.slice(-3)}`;
    };



    return (
        <div className={className} onClick={onClick}>
            <div className="w-full aspect-square">
                <img src={collectionData?.image} />
            </div>
            <h1 className='font-silkscreen text-[20px] text-black'>{formatName(collectionData?.name || name || '')}</h1>
            <p className='font-silkscreen text-[20px] --bold text-[#0099FE]'>{value} TON</p>
            <p className='flex items-center text-[16px] text-black'>
                <span className="text-black">{currentMintedCount}/</span>
                <span className="text-black">
                    {
                        totalMintCount ===0 ? <Infinity className="w-[18px] h-[18px]" color="#000000" /> : totalMintCount
                    }
                </span>
            </p>
        </div>
    )
}