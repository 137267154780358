export const app_version = "1.11.94";
export const OFFCHAIN_CONTENT_PREFIX = 0x01;
export const BACKEND_URL = process.env.BACKEND_URL;
export const defaultEndpoint = `https://${process.env.NETWORK === "testnet" ? "testnet." : ""
  }toncenter.com/api/v2/jsonRPC`;
export const creationTonPrice = "0.25";
export const telegramBotName = process.env.TELEGRAM_BOT_NAME;
export const telegramWebAppName = process.env.TELEGRAM_APP_NAME;
export const tonMainfestUrl = process.env.MANIFEST_URL;
export const masterTonContract = process.env.MASTER_CONTRACT || ""; // "EQBC2lmYlEC1jUkUNB20jhhZvw4r6JvCf-M63fDaiHvx1hTh"; // "EQBU5E4lX6cHG8ZIUU5D14lMEEEJO22Uko5kyfs3X5wwlSKj";

