import TonWeb from "tonweb";

export const sleep = (time: number) =>
  new Promise((resolve) => setTimeout(resolve, time));

export function getSignableMessage(): string {
  const dt = new Date().getTime();
  return "auth_" + String(dt - (dt % 600000));
}

export const formatTonAddress = (address: string): string => {
  if (!address) return '';
  try {
      // Convert raw address to user-friendly format
      const rawAddress = new TonWeb.utils.Address(address);
      const userFriendlyAddress = rawAddress.toString(true, true, true);
      return userFriendlyAddress;
  } catch (e) {
      return address;
  }
};
