import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { useTonConnectUI } from '@tonconnect/ui-react';
import { useSnackbar } from 'notistack';
import { useTonConnect } from "@/hooks/useTonConnect";
import { formatTonAddress } from "@/utils/common";
import { backend } from "@/services/backend";

interface Wallet {
    account: {
        address: string;
    };
    disconnect: () => Promise<void>;
}

interface WalletContextType {
    wallet: Wallet | null;
    isConnected: boolean;
    disconnect: () => Promise<void>;
    isMenuOpen: boolean;
    toggleMenu: () => void;
    walletAddress: string | null;
}

const isInTelegram = () => {
    return window.Telegram && window.Telegram.WebApp;
};

const WalletContext = createContext<WalletContextType | undefined>(undefined);

export function WalletProvider({ children }: { children: React.ReactNode }) {
    const {wallet} = useTonConnect() as { wallet: Wallet | null };
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [tonConnectUI] = useTonConnectUI();
    const { enqueueSnackbar } = useSnackbar();

    const walletAddress = useMemo(() => {
        if (!wallet) return null;
        const address = typeof wallet === 'string' ? wallet : wallet.account.address;
        return formatTonAddress(address);
    }, [wallet]);

    const toggleMenu = () => {
        setIsMenuOpen(prev => !prev);
    };

    useEffect(() => {
        const checkReferral = async () => {
            if (wallet) {
                console.log("Init data:", window.Telegram?.WebApp?.initData)
                try {
                    if(isInTelegram() && window.Telegram?.WebApp?.initData) {
                        const result = await backend.saveTelegram(window.Telegram.WebApp.initData, walletAddress!);
                        console.log(result);
                    }
                    
                    const urlParams = new URLSearchParams(window.location.search);
                    const refAddress = urlParams.get('ref');
                    
                    if(refAddress && refAddress !== walletAddress) {
                        await backend.registerReferral(
                            walletAddress!, 
                            refAddress  
                        );
                        
                        enqueueSnackbar("Referral registered successfully!", { 
                            variant: "success", 
                            autoHideDuration: 2000 
                        });
                    }
                    
                    enqueueSnackbar("Wallet connected successfully!", { 
                        variant: "success", 
                        autoHideDuration: 1000 
                    });
                } catch (error) {
                    console.error("Failed to process referral:", error);
                    enqueueSnackbar("Failed to process referral", { 
                        variant: "error", 
                        autoHideDuration: 2000 
                    });
                }
            }
        };
        checkReferral();
    }, [wallet, walletAddress]);

    const disconnect = async () => {
        try {
            await tonConnectUI.disconnect();
            enqueueSnackbar("Wallet is disconnected!", {
                variant: "info",
                autoHideDuration: 1000,
            });
        } catch (error) {
            console.error("Failed to disconnect:", error);
        }
    };

    return (
        <WalletContext.Provider value={{ 
            wallet, 
            isConnected: !!wallet,
            disconnect,
            isMenuOpen,
            toggleMenu,
            walletAddress
        }}>
            {children}
        </WalletContext.Provider>
    );
}

export function useWallet() {
    const context = useContext(WalletContext);
    if (context === undefined) {
        throw new Error('useWallet must be used within a WalletProvider');
    }
    return context;
} 