import * as React from "react";
import { JSX } from "react";

interface OwnerViewProps {
    isOwnerView: boolean;
    handleViewToggle: () => void;
    className?: string;
}

export default function OwnerView({ isOwnerView, handleViewToggle, className = '' }: OwnerViewProps) {
    return (
        <div
            className={`fixed z-[1] bg-black top-[90px] left-[50%] w-[190px] translate-x-[-50%] flex items-center justify-center gap-[10px] px-[14px] py-[6px] cursor-pointer ${className}`}
            onClick={handleViewToggle}
        >
            <div
                className={`text-[18px] transition-all duration-300 ${isOwnerView ? 'text-[#FE0000]' : 'text-[#0099FE]'
                    }`}
            >
                {isOwnerView ? 'OWNER VIEW' : 'USER VIEW'}
            </div>
            <div
                className="cursor-pointer hover:scale-110 transition-transform duration-300"
            >
                <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={`transition-transform duration-500 ${isOwnerView ? 'rotate-180' : 'rotate-0'
                        }`}
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.29169 0H15.7188V3.14734H18.8542V6.28422H22V9.43156H18.8542V6.28422H15.7188V3.14734H6.29169V6.28422H9.43753V9.43156H0.0104513V0H3.15629V3.14734H6.29169V0ZM3.13539 15.7158H0V12.5684H3.13539V15.7158H6.28124V18.8527H15.6979V15.7158H12.5625V12.5684H21.9791V22H18.8437V18.8527H15.6979V22H6.28124V18.8527H3.13539V15.7158Z"
                        className={`transition-colors duration-300 ${isOwnerView ? 'fill-[#FE0000]' : 'fill-[#0099FE]'
                            }`}
                    />
                </svg>
            </div>
        </div>
    );
}