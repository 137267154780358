import * as React from "react";
import Direction from "@/components/atomics/direction";
import { MenuType } from "@/types/leader-board";

interface LeaderBoardMenuProps {
    selectedMenu: MenuType;
    onMenuChange: (menu: MenuType) => void;
}

export default function LeaderBoardMenu({ selectedMenu, onMenuChange }: LeaderBoardMenuProps) {
    const menuItems: { id: MenuType; label: string[] }[] = [
        { id: 'sales volume', label: ['SALES', 'VOLUME'] },
        { id: 'sales quantity', label: ['SALES', 'QUANTITY'] },
        { id: 'refs.volume', label: ['REFS.', 'VOLUME'] },
    ];

    return (
        <div className="flex flex-col justify-center items-center">
            <h1 className="text-[44px]">LEADERBOARD</h1>
            <div className="flex justify-between items-center gap-x-7 text-[18px]">
                {menuItems.map(({ id, label }) => (
                    <div
                        key={id}
                        className={`relative flex flex-col items-center cursor-pointer transition-colors pt-[25px] ${
                            selectedMenu === id ? '--blue' : 'text-white'
                        }`}
                        onClick={() => onMenuChange(id)}
                    >
                        {label.map((text, index) => (
                            <span key={index}>{text}</span>
                        ))}
                        {selectedMenu === id && (
                            <div className="absolute top-[6px]">
                                <Direction 
                                    className="w-[11px] h-[19px] rotate-90"
                                    color="#0099FE"
                                />
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}