import * as React from "react";
import ContractCard from "@/components/atomics/contract-card";
import { useParams } from "react-router-dom";
import OwnerAvatar from "@/components/molecules/owner/owner-avatar";
import { useState, useMemo, useEffect } from "react";
import Direction from "@/components/atomics/direction";
import ShareButton from "@/components/atomics/share-button";
import InviteFriendButton from "@/components/atomics/invite-friend-button";
import { useNavigate } from "react-router-dom";
import { backend } from "@/services/backend";
import { useWallet } from "@/context/WalletContext";
import { DEFAULT_STATE } from "../owner/owner-edit-view";
import { OwnerState } from "../owner/owner-edit-view";
import ReferralDashboard from "./referral-dashboard";

export default function Referal() {
  const { address } = useParams();
  const [ownerData, setOwnerData] = useState<OwnerState>(DEFAULT_STATE);
  
  const username = useMemo(() => {
    if (!address) return 'unknown';
    const prefix = address.slice(0, 4);
    const suffix = address.slice(-4);
    return `USER#${prefix}...${suffix}`;
  }, [address]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOwnerData = async () => {
      try {
        if (!address) return;
        const photos = await backend.getOwnerPhotos(address);

        const latestAvatar = photos
          .filter(p => p.category === 'avatar')
          .reduce((prev, curr) => prev.id > curr.id ? prev : curr, { id: -1, url: ownerData.avatar });

        const latestBackground = photos
          .filter(p => p.category === 'cover')
          .reduce((prev, curr) => prev.id > curr.id ? prev : curr, { id: -1, url: ownerData.background });

        setOwnerData(prev => ({
          ...prev,
          avatar: latestAvatar?.url || prev.avatar,
          background: latestBackground?.url || prev.background,
        }));
      } catch (error) {
        console.error('Error fetching owner data:', error);
        alert(error);
      }
    };

    fetchOwnerData();
  }, []);

  return (
    <div className={`w-full relative`}>
      <div className="relative w-full h-[180px]">
        <img src={ownerData.background || '/assets/owner-bg.jpg'} alt="background" />    
        <div className={`absolute bottom-[-52px] left-[10px] z-[1] hover:cursor-pointer`} onClick={() => navigate(-1)}>
            <div className="flex gap-[10px] items-center">
                <Direction className="w-[24px] h-[24px] rotate-180" color="#0099FE" />
                <span className="text-[27px] text-[#0099FE]">BACK</span>
            </div>
        </div>
            
        <InviteFriendButton className="absolute bottom-[-52px] right-[80px] z-[1] opacity-[40%]" color="white"/>

        <ShareButton className="absolute bottom-[-49px] right-[28px] z-[1]"/>
        
        <div className="w-full absolute left-[50%] translate-x-[-50%] translate-y-[-80px] flex flex-col items-center justify-center">
          <div className="relative">
            <OwnerAvatar 
                className={`w-[143px] rounded-full`}
                image={ownerData.avatar} 
            /> 
           </div>  
          
          <div className={`text-[32px]`}>
            <span>{username}</span>
          </div>
          <ContractCard 
            className={`pt-[10px] pb-[10px] w-[calc(100vw-61px)] max-w-[374px]`}  
            length={12}
            text="WALLET" 
            address={address || ''} />
        </div>      
      </div>
      <ReferralDashboard 
        className="w-full px-[28px] pt-[175px]"
         walletAddress={address} />
    </div>
  );
} 