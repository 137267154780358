import { CHAIN } from "@tonconnect/sdk";
import { useTonConnectUI, useTonWallet } from "@tonconnect/ui-react";
import { Address, Sender, SenderArguments } from "@ton/core";
import { useCallback, useEffect, useRef, useState } from "react";
import { backend } from "@/services/backend";
import useInterval from "./useInterval";
import { LSService } from "@/services/storage";

export function useTonConnect(): {
  sender: Sender;
  connected: boolean;
  wallet: string | null;
  network: CHAIN | null;
} {
  const [tonConnectUI] = useTonConnectUI();
  const wallet = useTonWallet();
  const firstProofLoading = useRef<boolean>(true);
  const [authorized, setAuthorized] = useState(false);

  const recreateProofPayload = useCallback(async () => {
    if (firstProofLoading.current) {
      tonConnectUI.setConnectRequestParameters({ state: "loading" });
      firstProofLoading.current = false;
    }

    const payload = await backend.generatePayload();

    if (payload) {
      console.log("Found payload:", payload);
      tonConnectUI.setConnectRequestParameters({
        state: "ready",
        value: payload,
      });
    } else {
      tonConnectUI.setConnectRequestParameters(null);
    }
  }, [tonConnectUI, firstProofLoading]);

  if (firstProofLoading.current) {
    recreateProofPayload();
  }

  useInterval(recreateProofPayload, 1200000);

  useEffect(() => {
    recreateProofPayload();
  }, [tonConnectUI]);

  useEffect(
    () =>
      tonConnectUI.onStatusChange(async (w) => {
        if (!w) {
          setAuthorized(false);
          return;
        }
        // const payload = await backend.generatePayload()
        console.log("Items:", w.connectItems);

        let retryCount = 0;
        while (!w.connectItems && retryCount < 5) {
          await new Promise((resolve) => setTimeout(resolve, 500));
          retryCount++;
        }

        if (!w.connectItems) {
          console.error("Connect items are still undefined after retries.");
		  const accessToken = LSService.getTonAuthToken();
		  if (!accessToken) {
			tonConnectUI.disconnect();
			return;
		  }
		  const checkResult = await backend.checkToken(accessToken);
		  if (!checkResult) {
			LSService.deleteTonAuthToken();
			tonConnectUI.disconnect();
		  }
          return;
        }

        console.log("Connect items:", w.connectItems);

        if (w.connectItems?.tonProof && "proof" in w.connectItems.tonProof) {
          backend
            .checkWalletRights(
              w.connectItems.tonProof.proof,
              w.account
            )
            .then((res) => {
              console.log(res);
			  if (res) {
				LSService.setTonAuthToken(res)
			  }
			  backend.checkToken().then((res) => {
				console.log(res)
			  })
            });
          // await TonProofDemoApi.checkProof(w.connectItems.tonProof.proof, w.account);
        }

        /* if (!TonProofDemoApi.accessToken) {
				tonConnectUI.disconnect();
				setAuthorized(false);
				return;
			} */

        // setAuthorized(true);
      }),
    [tonConnectUI]
  );

  return {
    sender: {
      send: async (args: SenderArguments) => {
        tonConnectUI.sendTransaction({
          messages: [
            {
              address: args.to.toString(),
              amount: args.value.toString(),
              payload: args.body?.toBoc().toString("base64"),
            },
          ],
          validUntil: Date.now() + 300000,
        });
      },
      address: wallet?.account.address
        ? Address.parse(wallet?.account.address as string)
        : undefined,
    },
    connected: !!wallet?.account.address,
    wallet: wallet?.account.address ?? null,
    network: wallet?.account.chain ?? null,
  };
}
