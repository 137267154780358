import * as React from "react";
import { LeaderboardItemType } from "@/types/leader-board";
import { MenuType } from "@/types/leader-board";
import LeaderBoardTop from "@/components/molecules/leader-board/leader-board-top";
import LeaderBoardItem from "@/components/molecules/leader-board/leader-board-item";
import { useNavigate } from "react-router-dom";


interface LeaderBoardTableProps {
    data: LeaderboardItemType[];
    selectedMenu: MenuType;
}

export default function LeaderBoardTable({ data, selectedMenu }: LeaderBoardTableProps) {
    const navigate = useNavigate();
    const [imgUrl, setImgUrl] = React.useState('/assets/sales_volume.png');

    const formatValue = (value: number): string => {
        if (selectedMenu === 'sales quantity') {
            return value.toString();
        }
        return value.toFixed(2);
    };

    React.useEffect(() => {
        if (selectedMenu === 'sales volume') {
            setImgUrl('/assets/sales_volume.png');
        } else if (selectedMenu === 'sales quantity') {
            setImgUrl('/assets/sales_quantity.png');
        } else if (selectedMenu === 'refs.volume') {
            setImgUrl('/assets/refs_volume.gif');
        }
    }, [selectedMenu]);

    return (
        <div className="w-full mt-[30px] relative">
            <div className="w-full flex">
                <img
                    src={imgUrl}
                    alt="leader-board-bg"
                    className="mt-[30px] absolute left-0 -z-[1] object-top object-none"
                />
            </div>
            <LeaderBoardTop
                className="w-full"
                selectedMenu={selectedMenu}
                bgColor="#FFD900"
                image={data[0].avatar || ''}
                name={data[0].name}
                rank={data[0].rank.toString()}
                value={formatValue(data[0].value)}
                onClick={() => navigate(`/owner/${data[0].address}`)}
            />
            <div className="flex justify-center gap-[20px] pt-[26px]">
                <LeaderBoardTop
                    className="w-full"
                    bgColor="#C2C2C2"
                    image={data[1].avatar || ''}
                    name={data[1].name}
                    rank={data[1].rank.toString()}
                    value={formatValue(data[1].value)}
                    selectedMenu={selectedMenu}
                    onClick={() => navigate(`/owner/${data[1].address}`)}
                />
                <LeaderBoardTop
                    className="w-full"
                    bgColor="#DC9E3C"
                    image={data[2].avatar || ''}
                    name={data[2].name}
                    rank={data[2].rank.toString()}
                    value={formatValue(data[2].value)}
                    selectedMenu={selectedMenu}
                    onClick={() => navigate(`/owner/${data[2].address}`)}
                />
            </div>
            <div className="pt-[20px]">
                {data.slice(3).map((item) => (
                    <LeaderBoardItem
                        key={item.rank}
                        rank={item.rank}
                        name={item.name}
                        value={item.value}
                        address={item.address}
                        avatar={item.avatar}
                        selectedMenu={selectedMenu}
                        onClick={() => navigate(`/owner/${item.address}`)}
                    />
                ))}
            </div>
        </div>
    );
}