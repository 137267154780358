import * as React from "react";

export default function Infinity({
  className,
  color,
  onClick
}: {
  className?: string,
  color?: string,
  onClick?: (event: React.MouseEvent) => void
}) {
   return (
      <div 
        className={`${className} flex items-center justify-center relative`}
        onClick={onClick}
      >
            <svg width="51" height="28" viewBox="0 0 51 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M43.1558 0H35.3116V4V8H31.3895V12H27.4674V16H31.3895V20H35.3116V24H43.1558H47.0779V20H51V8H47.0779V4H43.1558V0ZM23.5326 8H19.6105V4H15.6884V0H7.84421V4V8H3.9221V20H7.84421V24V28H15.6884V24H19.6105V20H23.5326V16H19.6105V20H15.6884V24H7.84421H3.9221V20H0V8H3.9221V4H7.84421H15.6884V8H19.6105V12H23.5326V8ZM27.4674 12V8H31.3895V4H35.3116H43.1558V8H47.0779V20H43.1558V24V28H35.3116V24H31.3895V20H27.4674V16H23.5453V12H27.4674Z" fill={color ? color : 'white'}/>
            </svg>
        </div>
    )
}