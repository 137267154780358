import React from "react";

interface NFTCardProps {
    className?: string;
    image: string;
    id: string;
    name: string;
    mintedAddress: string;
    onClick?: () => void;
}

const NFTCard = ({ className, image, id, name, mintedAddress, onClick }: NFTCardProps) => {
    return (
        <div className={`${className} flex flex-col items-center justify-center`} onClick={onClick}>
            <img src={image} alt={name} className="w-full aspect-square" />
            <div className="flex flex-col w-full text-black">
                <div className="flex justify-between">
                    <p className="text-[12px] w-[calc(100%-10px)] truncate">{name}</p>
                    {/* <p className="text-[12px]">{name.slice(-3)}</p> */}
                </div>
                <p className="text-[12px]">{id}</p>
                {/* <p className="text-[10px]">OWNER:</p>
                <div className="flex justify-between font-spline-sans-mono">
                    <p className="text-[10px] w-[calc(100%-10px)] truncate">{ownerAddress.slice(0, -3)}</p>
                    <p className="text-[10px]">{ownerAddress.slice(-3)}</p>
                </div> */}
            </div>
        </div>
    )
}

export default NFTCard;