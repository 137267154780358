import * as React from "react";
interface StatisticsType {
    className: string;
    referralCount: number;
    collectionsCreated: number;
    nftMinted: number;
    referralsCashback: number;
    tonsCollected: number;
}

export default function Statistics({ className, referralCount, collectionsCreated, nftMinted, referralsCashback, tonsCollected }: StatisticsType) {
    return (
        <div className={className}>
            <h3 className="text-[32px]">STATISTICS</h3>
            <div className="pt-[20px]">
                <div className="flex items-center justify-between text-[13px] font-spline-sans-mono border-b border-[#757575] pt-[5px]">
                    <span>REFERRAL COUNT</span>
                    <span>{referralCount}</span>
                </div>
                <div className="flex items-center justify-between text-[13px] font-spline-sans-mono border-b border-[#757575] pt-[5px]">
                    <span>Collections created</span>
                    <span>{collectionsCreated}</span>
                </div>
                <div className="flex items-center justify-between text-[13px] font-spline-sans-mono border-b border-[#757575] pt-[5px]">
                    <span>NFT minted</span>
                    <span>{nftMinted}</span>
                </div>
                <div className="flex items-center justify-between text-[13px] font-spline-sans-mono border-b border-[#757575] pt-[5px]">
                    <span>Referrals cashback</span>
                    <span>{referralsCashback}</span>
                </div>
                <div className="flex items-center justify-between text-[13px] text-[#0099FE] font-spline-sans-mono border-b border-[#757575] pt-[5px]">
                    <span>Tons collected</span>
                    <span>{referralCount}</span>
                </div>
            </div>
        </div>
    )
}