

export class LSService {
    private static readonly tonAuthTokenKey = "tonAuthKey";

    public static getTonAuthToken (): string | null {
        return localStorage.getItem(this.tonAuthTokenKey);
    }

    public static setTonAuthToken (token: string) {
        localStorage.setItem(this.tonAuthTokenKey, token);
    }

    public static deleteTonAuthToken () {
        localStorage.removeItem(this.tonAuthTokenKey);
    }
 }