import * as React from "react";

interface EditButtonProps {
    className?: string;
    color?: string;
    onClick?: () => void;
}

export default function EditButton({ className, color, onClick }: EditButtonProps) {
    return (
        <div className={className} onClick={onClick}>
           <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M23.625 27V16.875H20.25V23.625C14.6213 23.625 9.00374 23.625 3.375 23.625C3.375 17.9963 3.375 12.3675 3.375 6.75H10.125V3.375C6.75 3.375 3.375 3.375 0 3.375C0 11.2463 0 19.1175 0 27C7.87126 27 15.7537 27 23.625 27ZM27 6.75H23.625V10.125H20.25V13.5H16.875V16.875H13.5V20.25H10.125H6.75V16.875V13.5H10.125V10.125H13.5V6.75H16.875V3.375H20.25V0H23.625V3.375H27V6.75ZM10.125 16.875H13.5V13.5H10.125V16.875ZM13.5 10.125V13.5H16.875V10.125H13.5ZM20.25 6.75H16.875V10.125H20.25V6.75Z" fill={color || 'white'}/>
            </svg>
        </div>
    )
}