import * as React from "react";
import { useState } from "react";
import LeaderBoardMenu from "@/components/molecules/leader-board/leader-board-menu";
import LeaderBoardTable from "@/components/molecules/leader-board/leader-board-table";
import { MenuType } from "@/types/leader-board";
import { leaderboardData } from "@/types/leader-board";

export default function LeaderBoard() {
    const [selectedMenu, setSelectedMenu] = useState<MenuType>('sales volume');

    const handleMenuChange = (menu: MenuType) => {
        setSelectedMenu(menu);
    };

    return (
        <div>
            <LeaderBoardMenu selectedMenu={selectedMenu} onMenuChange={handleMenuChange}/>
            <LeaderBoardTable 
                data={leaderboardData[selectedMenu]} 
                selectedMenu={selectedMenu}
            />
        </div>
    )
}

