import * as React from "react";
import PlusButton from "@/components/atomics/plus-button";
import MinusButton from "@/components/atomics/minus-button";

interface CollapsibleCardProps {
    className?: string;
    viewClassName?: string;
    editTitle: string;
    viewTitle: string;
    isEditView: boolean;
    children: React.ReactNode;
}

export default function CollapsibleCard({ editTitle, viewTitle, children, className, isEditView, viewClassName }: CollapsibleCardProps) {
    const [isOpen, setIsOpen] = React.useState(false);
    return (
        <div>
            {isEditView ? (
                <div className="flex flex-col pb-[22px]">
                    <div className={`flex justify-between items-center ${className}`}>
                        <span>{editTitle}</span>
                        {isOpen ?
                            <MinusButton
                                className="w-[23px] h-[23px] flex items-center justify-center"
                                onClick={() => setIsOpen(false)} /> :
                            <PlusButton
                                className="w-[23px] h-[23px] flex items-center justify-center"
                                onClick={() => setIsOpen(true)} />}
                    </div>
                    <div className={`
                        overflow-hidden transition-all duration-300 ease-in-out
                        ${isOpen ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}
                    `}>
                        {children}
                    </div>
                </div>
            ) : (
                <>
                    <div className={`flex justify-between items-center ${viewClassName}`}>
                        <span>{viewTitle}</span>
                    </div>
                    {children}
                </>
            )}
        </div>
    )
}