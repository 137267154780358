import * as React from "react";
import { useState, useEffect } from "react";
import Header from "@/components/template/dashboard-layout/header";
import Footer from "@/components/template/dashboard-layout/footer";
import LeaderBoard from "@/components/organisms/leader-board";

export default function LeaderBoardPage() {
    
  
    return (
      <>
        <Header />
        <LeaderBoard />
        <Footer />
      </>
    );
  }