import * as React from "react";
import CircleBg from "@/components/atomics/circle-bg";
import { MenuType } from "@/types/leader-board";

interface LeaderBoardTopProps {
    className?: string;
    bgColor?: string;
    image: string;
    name: string;
    rank: string;
    value: string;
    selectedMenu: MenuType;
    onClick?: () => void;
}

export default function LeaderBoardTop({ className, bgColor, image, name, rank, value, selectedMenu, onClick }: LeaderBoardTopProps) {
    return (
        <div className={`relative flex flex-col items-center hover:cursor-pointer ${className}`} onClick={onClick}>
            <CircleBg className="absolute top-[-6px] translate-x-[-50%] left-[50%] w-[158px] h-[158px]"
                color={bgColor}
            />
            <img src={image} alt={name} className="w-[146px] h-[147px] rounded-full object-cover" />
            <div className="flex flex-col items-center text-[20px] pt-[10px]">
                <span className="text-[18px]">{name}</span>
                <span className="text-[18px] --blue">{value} {selectedMenu === 'sales quantity' ? 'NFTS' : 'TON'}</span>
                <span className="text-[18px]">{rank} PLACE</span>
            </div>
        </div>
    )
}