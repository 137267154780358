import * as React from "react";
import { useState } from "react";
import { BiCopy, BiCheck } from "react-icons/bi";

interface CopyToClipboardProps {
    text: string;
    className?: string;
    length?: number;
}

function CopyToClipboard({ text, className, length }: CopyToClipboardProps) {
    const [copied, setCopied] = useState(false);

    const formatAddress = (addr: string) => {
        if (addr.length > 14) {
            return `${addr.slice(0, 21)}.........`;
        }
        return addr;
    };

    const handleCopy = async () => {
        await navigator.clipboard.writeText(text);
        setCopied(true);
        setTimeout(() => setCopied(false), 400);
    };

    return (
        <div className={`${className} flex justify-between gap-[20px] relative`}>
            <div className={`flex justify-start font-spline-sans-mono text-[16px] --light ${copied ? 'text-[#0099FE]' : 'text-white'} truncate max-w-[calc(100vw-97px)]`}>
               {formatAddress(text)}
            </div>
            <div className="flex items-center font-spline-sans-mono">
                {copied && (
                    <span className="absolute w-full top-[-16px] left-[0px] text-center text-[#0099FE]">
                        COPIED!
                    </span>
                )}
                <BiCopy onClick={handleCopy} className={`cursor-pointer w-[24px] h-[24px] ${copied ? 'text-[#0099FE]' : 'text-white'}`} />
            </div>
        </div>
    );
}

export default CopyToClipboard;
