import React from "react";
import Referal from "@/components/organisms/referal";
import Header from "@/components/template/dashboard-layout/header";

function ReferalPage() {
    return (
        <>
            <Header />
            <Referal />
        </>
    )
}

export default ReferalPage;