import * as React from "react";
import { useRef, useEffect } from "react";

// Add utility function to detect and convert links to anchor tags
const convertLinksToAnchors = (text: string) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, (url) => `<a href="${url}" target="_blank" rel="noopener noreferrer" class="text-[#0099FE] hover:underline">${url}</a>`);
};

interface TextAreaType {
  className?: string;
  placeholder?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  label?: string;
  essential?: boolean;
  totalTextCount?: number;
  notEditable?: boolean;
  isLoading?: boolean;
  onHelpClick?: () => void;
}

export default function TextArea({
  className,
  placeholder,
  value,
  onChange,
  label,
  essential,
  totalTextCount,
  notEditable,
  isLoading,
  onHelpClick
}: TextAreaType) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const adjustHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      if (notEditable) {
        textarea.style.height = 'auto';
        textarea.style.height = `${Math.min(400, textarea.scrollHeight)}px`;
      } else {
        textarea.style.height = 'auto';
        textarea.style.height = `${Math.max(150, textarea.scrollHeight)}px`;
      }
    }
  };

  useEffect(() => {
    adjustHeight();
  }, [value, notEditable]);

  // Add handleKeyDown function to handle Enter key
  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      const textarea = event.currentTarget;
      const cursorPosition = textarea.selectionStart;
      const textBeforeCursor = textarea.value.substring(0, cursorPosition);
      const textAfterCursor = textarea.value.substring(cursorPosition);

      const newValue = textBeforeCursor + '\n' + textAfterCursor;
      if (onChange) {
        const syntheticEvent = {
          target: { value: newValue }
        } as React.ChangeEvent<HTMLTextAreaElement>;
        onChange(syntheticEvent);
      }
    }
  };

  return (
    <div className={`relative`}>
      <div className={`${isLoading ? 'text-[#2B2B2B]' : ''} relative text-[24px] text-center pb-[10px]`}>
        {essential && <span className={`${isLoading ? 'text-[#2B2B2B]' : 'text-[#FF0000]'} `}>*</span>}
        {label}
        {label && (
          <div className="absolute right-0 top-0">
            <span className={`${isLoading ? 'text-[#2B2B2B]' : 'text-[#0099FE]'}`} onClick={onHelpClick}>?</span>
          </div>
        )}
      </div>
      {notEditable && value ? (
        <div
          className={`${className} w-full text-[#757575] bg-black ${isLoading ? 'text-[#484848] cursor-not-allowed' : ''} outline-none text-[13px] font-spline-sans-mono whitespace-pre-wrap`}
          dangerouslySetInnerHTML={{ __html: convertLinksToAnchors(value) }}
        />
      ) : (
        <textarea
          ref={textareaRef}
          onKeyDown={handleKeyDown}
          className={`${className} w-full ${notEditable && (value?.length ?? 0) > 0 ? 'text-[#757575]' : 'p-[0px]'} ${notEditable ? 'bg-black' : 'bg-[#232323]'} ${isLoading ? 'text-[#484848] cursor-not-allowed' : ''} outline-none text-[13px] font-spline-sans-mono placeholder-[#606060] resize-none whitespace-pre-wrap`}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          readOnly={notEditable}
          maxLength={totalTextCount}
        />
      )}
      {!notEditable && (
        <div className="absolute bottom-[6px] right-[4px] text-[13px] font-spline-sans-mono">
          <span className={`${isLoading ? 'text-[#484848]' : ''}`}>{value?.length}/{totalTextCount}</span>
        </div>
      )}
    </div>
  )
}