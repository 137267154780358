import * as React from "react";

interface EditView {
    className?: string;
    isChange?: boolean;
    handleSave: () => void;
    handleCancel: () => void;
}

export default function EditView({ className, handleSave, handleCancel, isChange }: EditView) {
    return (
        <div className={`${className} px-[24px] pb-[12px] flex flex-col gap-[10px] justify-center items-center`}>
            <div className="flex justify-center items-center">
                <span className="text-[18px] text-[red]">EDIT VIEW</span>
            </div>
            <div className="flex gap-[25px]" >
                <div className={`${isChange ? 'hover: cursor-pointer' : ''}`} onClick={isChange ? handleSave : () => { }}>
                    {isChange ? (
                        <svg width="41" height="29" viewBox="0 0 41 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M5.77638 17.3675H0V11.5911H5.77638V17.3675ZM11.5911 23.1055H5.81476V17.3291H11.5911V23.1055H17.3675V17.3291H23.1439V11.5528H28.9203V5.77638H34.6966V0H40.473V5.77638H34.6966V11.5528H28.9203V17.3291H23.1439V23.1055H17.3675V28.8819H11.5911V23.1055Z" fill="#00FF2F" />
                        </svg>
                    ) : (
                        <svg width="41" height="29" viewBox="0 0 41 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.77638 17.3675H0V11.5911H5.77638V17.3675ZM11.5911 23.1055H5.81476V17.3291H11.5911V23.1055H17.3675V17.3291H23.1439V11.5528H28.9203V5.77638H34.6966V0H40.473V5.77638H34.6966V11.5528H28.9203V17.3291H23.1439V23.1055H17.3675V28.8819H11.5911V23.1055Z" fill="#2A2A2A" />
                        </svg>
                    )}
                </div>
                <div className="hover: cursor-pointer" onClick={handleCancel}>
                    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M11.5528 11.5528H17.3291V5.77638H23.1055V0H28.8819V5.77638H23.1055V11.5528H17.3291V17.3291H23.1055V23.1055H28.8819V28.8819H23.1055V23.1055H17.3291V17.3291H11.5528V23.1055H5.77638V28.8819H0V23.1055H5.77638V17.3291H11.5528V11.5528H5.77638V5.77638H0V0H5.77638V5.77638H11.5528V11.5528Z" fill="#FF0000" />
                    </svg>
                </div>
            </div>
        </div>
    )
}