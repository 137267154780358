import React, { useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import "./scss/app.scss";
import "./scss/app.scss";
import { CollectionProvider } from "@/context/CollectionContext";
import CollectionCreatePage from "./pages/createCollection";
import SingleCollectionPage from "./pages/collection";
import MainPage from "./pages/mainPage";
import AccountPage from "./pages/account";
import SingleItemPage from "./pages/item";
import ConfirmWalletPage from "./pages/confirmWallet";
import { app_version, masterTonContract } from "./config";
import CreateCollectionPage from "./pages/create-collection";
import CreateCollectionStepPage from "./pages/create-collection-step";
import NFTSMintPage from "./pages/mint";
import OwnerPage from "./pages/owner";
import NftPage from "./pages/nft";
import ReferalPage from "./pages/referal";
import LeaderBoardPage from "./pages/leaderBoard";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { useWallet, WalletProvider } from "@/context/WalletContext";
import WebApp from "@twa-dev/sdk";
import Version from "./components/atomics/version";
import { getUrlFromMiniAppParam } from "./utils/telegram";
import { useTonConnectUI } from "@tonconnect/ui-react";
import { backend } from "./services/backend";
import { connector } from "./functions";

const sesionStorageVisitKey = "visited_first"

const App = () => {

  const [tonConnectUI] = useTonConnectUI();

  useEffect(() => {
    const startParam = WebApp.initDataUnsafe?.start_param;
    WebApp.expand();
    WebApp.disableVerticalSwipes();

    /* tonConnectUI.onStatusChange((wallet) => {
      console.log("On change:", wallet)
      if (!wallet) return;
      console.log("Proof:", wallet.connectItems?.tonProof, wallet.account);
      if (wallet.connectItems?.tonProof && 'proof' in wallet.connectItems.tonProof) {
				backend.checkWalletRights (wallet.account.address, wallet.connectItems.tonProof.proof, wallet.account).then((res) => {
          console.log("Res:", res)
        })
			}
    }) */

    connector.onStatusChange((wallet) => {
      console.log("Connector status changed", wallet?.account, wallet?.connectItems?.tonProof)
    })

    const isVisited = sessionStorage.getItem(sesionStorageVisitKey);
    if (startParam && !isVisited) {
      const path = `/${getUrlFromMiniAppParam(startParam)}`;
      const currentPath = `${document.location.pathname}${document.location.search}`;
      if (path !== currentPath && window.history.length) {
        document.location.pathname = `${path}`;
      }
      sessionStorage.setItem(sesionStorageVisitKey, "1");
    }
  }, [document.location.pathname]);

  return (
    <BrowserRouter>
      <SnackbarProvider anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <WalletProvider>
          <CollectionProvider>
            <Routes>
              <Route path="/collection/create" element={<CreateCollectionPage />} />
              <Route path="/owner/:address" element={<OwnerPage />} />
              <Route path="/collection/create/:step" element={<CreateCollectionStepPage />} />
              <Route path="/collection/:collection" element={<NFTSMintPage />} />
              <Route path="/nft/:id" element={<NftPage />} />
              <Route path="/walletconfirm" element={<ConfirmWalletPage />} />
              <Route path="/referal/:address" element={<ReferalPage />} />
              <Route path="/leaderboard" element={<LeaderBoardPage />} />
              {/* <Route path="/owner/:address" element={<AccountPage />}/> */}
              {/* <Route path="/collection/create" element={<CollectionCreatePage />}/> */}
              {/* <Route path="/collection/:collection" element={<SingleCollectionPage />} /> */}
              <Route path="/item/:item" element={<SingleItemPage />} />
              <Route path="/" element={<CreateCollectionPage />} />
            </Routes>
            <Version version={app_version} />
          </CollectionProvider>
        </WalletProvider>
      </SnackbarProvider>
    </BrowserRouter>
  );
};

export default App;
