import * as React from "react";
import CreateCollectionButton from "../../molecules/create-collection/create-collection-button";
import DailyNewsCard from "../../molecules/create-collection/daily-news-card";
import { dailyNewsData, collectionData } from "../../../data/daily-news";
import CollectionCard from "../../../components/molecules/create-collection/collection-card";
import { useNavigate } from "react-router-dom";
import { useWallet } from "@/context/WalletContext";
import { useSnackbar } from "notistack";
import {backend} from "@/services/backend";
import { CollectionDataCache } from "@/types/collection";
import LoadMoreButton from "@/components/atomics/load-more-button";

export default function CreateCollection() {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { wallet, isConnected, toggleMenu } = useWallet(); 
    const scrollContainerRef = React.useRef<HTMLDivElement>(null);
    const [displayCount, setDisplayCount] = React.useState(4);
    const [isLoading, setIsLoading] = React.useState(false);
    const [lastCollections, setLastCollections] = React.useState<CollectionDataCache[]>([]);

    React.useEffect(() => {
        backend.getAllLastCollections().then((collections) => {
            setLastCollections(collections);
        });
    }, []);

    console.log(lastCollections, "lastCollections");

    const handleCreateCollection = () => {
        if (!isConnected) {
            enqueueSnackbar("Wallet is not connected!", {
                variant: "error",
                autoHideDuration: 1000,
            });
            toggleMenu();
            return;
        }
        navigate('/collection/create/1');
    }

    const handleScrollRight = () => {
        if (scrollContainerRef.current) {
            const container = scrollContainerRef.current;
            const isAtEnd = container.scrollLeft + container.clientWidth >= container.scrollWidth - 10; // Adding small buffer

            if (isAtEnd) {
                container.scrollTo({
                    left: 0,
                    behavior: 'smooth'
                });
            } else {
                container.scrollBy({
                    left: 163, // Width of one card + gap
                    behavior: 'smooth'
                });
            }
        }
    };

    const handleLoadMore = () => {
        setIsLoading(true);
        setTimeout(() => {
            if (displayCount >= lastCollections.length) {
                setDisplayCount(4);
            } else {
                setDisplayCount(prev => Math.min(prev + 4, lastCollections.length));
            }
            setIsLoading(false);
        }, 300);
    };

    return (
        <div className="w-full">
            <div className="relative w-full h-[300px] ">
                <img src='/background.png'/>
                <div className="w-[82%] absolute top-[26px] left-1/2  -translate-x-1/2">
                    <div className="w-full">
                        <img src='/nft-logo.png'/>
                    </div>
                    <div className="pt-[12px] pl-[10px] pr-[10px] flex flex-col  justify-center text-[15px] ">
                        <span className="text-center">SIMPLIFYING <a className="text-[#0099FE]">WEB3</a> MONETIZATION FOR DEVELOPERS</span>              
                    </div>
                </div>
              <CreateCollectionButton 
                className="w-[163px]" 
                onClick={()=>handleCreateCollection()}/>
            </div>
            {/* <div className="pl-[12px] pt-[116px]">
                <div className="pl-[20px] pb-[23px]">
                    <span className="text-[32px]">DAILY NEWS</span>
                </div>
                <div 
                 ref={scrollContainerRef}
                className="flex gap-[10px] pb-[10px] overflow-hidden">
                    {dailyNewsData.map((item, index) => (
                        <DailyNewsCard 
                            key={index}
                            title={item.title}
                            description={item.description}
                            image={item.image}
                            className="w-[153px] flex-shrink-0" />
                    ))}
                </div>
                <div 
                    className="flex justify-end items-center pr-[36px] pt-[17px] pb-[23px] cursor-pointer"
                    onClick={handleScrollRight} >
                    <span className="text-[#0099FE] text-[24px]">MORE</span>
                    <div className="w-[19px] h-[19px]">
                        <img src="/assets/direction.svg" />
                    </div>
                </div>
            </div> */}
            <div className="bg-black h-[170px]"></div>
            <div className="w-full bg-white pr-[10px] pl-[10px]  pt-[40px] pb-[20px]">
                <div className="pl-[20px] pb-[23px]">
                    <span className="text-[30px] text-black">NEW COLLECTIONS</span>
                </div>
                <div className="grid grid-cols-2 gap-[10px] transition-all duration-500 ease-in-out">
                    {lastCollections.slice(0, displayCount).map((item, index) => (
                        <CollectionCard 
                            key={index}
                            className={`w-full flex-shrink-0 transition-all duration-300 ease-in-out ${
                                isLoading ? 'animate-pulse opacity-60' : 'opacity-100'
                            }`}
                            onClick={() => navigate(`/collection/${item.address}`)}
                            data_url={item.data_url}
                            value={item.price * (item.next_item_index || 0) / 1000000000}
                            totalMintCount={item.mint_limit}
                            currentMintedCount={item?.next_item_index ?? 0}
                            
                        />
                    ))}
                </div>
                {lastCollections.length > 4 && (
                    <LoadMoreButton 
                        isLoading={isLoading}
                        hasMore={displayCount < lastCollections.length}
                        onLoadMore={handleLoadMore}
                    />
                )}
            </div>

       </div>
    )
}
