export type MenuType = 'sales volume' | 'sales quantity' | 'refs.volume';

export type LeaderboardItemType = {
    rank: number;
    name: string;
    value: number;
    address: string;
    avatar?: string;
  }


  export const leaderboardData: Record<string, LeaderboardItemType[]> = {
    'sales volume': [
      { rank: 1, name: 'Crypto Punk', value: 112, address: '0x1234...5678', avatar: '/assets/avatar.png' },
      { rank: 2, name: 'Bored Ape', value: 97, address: '0x8765...4321', avatar: '/assets/avatar2.png' },
      { rank: 3, name: 'Doodles', value: 64, address: '0x2468...1357', avatar: '/assets/avatar3.png' },
      { rank: 4, name: 'Azuki', value: 38, address: '0x1357...2468', avatar: '/assets/avatar.png' },
      { rank: 5, name: 'CloneX', value: 20, address: '0x9876...5432', avatar: '/assets/avatar2.png' },
      { rank: 6, name: 'Crypto Punk', value: 112, address: '0x1234...5678', avatar: '/assets/avatar.png' },
      { rank: 7, name: 'Bored Ape', value: 97, address: '0x8765...4321', avatar: '/assets/avatar2.png' },
      { rank: 8, name: 'Doodles', value: 64, address: '0x2468...1357', avatar: '/assets/avatar3.png' },
      { rank: 9, name: 'Azuki', value: 38, address: '0x1357...2468', avatar: '/assets/avatar.png' },
      { rank: 10, name: 'CloneX', value: 20, address: '0x9876...5432', avatar: '/assets/avatar2.png' }
    ],
    'sales quantity': [
      { rank: 1, name: 'Azuki', value: 1245, address: '0x1357...2468', avatar: '/assets/avatar3.png' },
      { rank: 2, name: 'Doodles', value: 987, address: '0x2468...1357', avatar: '/assets/avatar.png' },
      { rank: 3, name: 'Crypto Punk', value: 856, address: '0x1234...5678', avatar: '/assets/avatar2.png' },
      { rank: 4, name: 'Bored Ape', value: 754, address: '0x8765...4321', avatar: '/assets/avatar3.png' },
      { rank: 5, name: 'CloneX', value: 623, address: '0x9876...5432', avatar: '/assets/avatar.png' },
    ],
    'refs.volume': [
      { rank: 1, name: 'User#1234', value: 122, address: '0x5678...1234', avatar: '/assets/avatar2.png' },
      { rank: 2, name: 'User#5678', value: 100, address: '0x1234...5678', avatar: '/assets/avatar3.png' },
      { rank: 3, name: 'User#9012', value: 80, address: '0x9012...3456', avatar: '/assets/avatar.png' },
      { rank: 4, name: 'User#3456', value: 60, address: '0x3456...7890', avatar: '/assets/avatar2.png' },
      { rank: 5, name: 'User#7890', value: 40, address: '0x7890...1234', avatar: '/assets/avatar3.png' },
    ]
  }