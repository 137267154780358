import * as React from "react";

export default function CheckIcon({
  className,
  color,
  onClick
}: {
  className?: string,
  color?: string,
  onClick?: (event: React.MouseEvent) => void
}) {
   return (
      <div 
        className={`${className} flex items-center justify-center`}
        onClick={onClick}
      >
        <svg width="41" height="29" viewBox="0 0 41 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M5.77638 17.3675H0V11.5911H5.77638V17.3675ZM11.5911 23.1055H5.81476V17.3291H11.5911V23.1055H17.3675V17.3291H23.1439V11.5528H28.9203V5.77638H34.6966V0H40.473V5.77638H34.6966V11.5528H28.9203V17.3291H23.1439V23.1055H17.3675V28.8819H11.5911V23.1055Z" fill={color ? color : '#0099FE'}/>
        </svg>
        </div>
    )
}