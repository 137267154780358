import * as React from "react";
import { FaTelegramPlane } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { IoIosRocket } from "react-icons/io";
import { GetgemsIcon } from "../../atomics/getgems-icon";

// Add URL validation helper
const isValidUrl = (url: string): boolean => {
    try {
        new URL(url);
        return true;
    } catch {
        return false;
    }
};

export default function Social({
    telegram,
    rocket,
    twitter,
    getgems,
    name,
    value,
    onChange,
    isEditView
}: {
    telegram?: string;
    rocket?: string;
    twitter?: string;
    getgems?: string;
    name: string;
    value: string;
    onChange: (value: string) => void;
    isEditView: boolean;
}) {
    const socialLinks = [
        { icon: FaTelegramPlane, link: telegram, name: 'telegram' },
        { icon: IoIosRocket, link: rocket, name: 'rocket' },
        { icon: FaXTwitter, link: twitter, name: 'twitter' },
        { icon: GetgemsIcon, link: getgems, name: 'getgems' },
    ];


    return (
        <div className="flex justify-between items-center gap-[20px]">
            {socialLinks
                .filter(({ name: linkName }) => linkName === name)
                .map(({ icon: Icon, link, name: linkName }) => (
                    isEditView ? (
                        <Icon key={linkName} className="w-[29px] h-[24px] text-white" aria-label={linkName} />
                    ) : (
                        value && (
                            <a
                                key={linkName}
                                href={link}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Icon key={linkName} className="w-[29px] h-[24px] text-white hover:text-[#0099FE] transition-colors" aria-label={linkName} />
                            </a>
                        )
                    )
                ))}
            {isEditView && (
                <input
                    className="w-full h-[31px] p-[5px] bg-[#232323] outline-none text-[16px] font-spline-sans-mono placeholder-[#606060]"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    placeholder={`Enter ${name} URL`}
                    type="url"
                    pattern="https?://.*"
                />
            )}
        </div>
    );
}


