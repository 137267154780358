import * as React from "react";
import Direction from "@/components/atomics/direction";

interface LoadMoreButtonProps {
    isLoading: boolean;
    hasMore: boolean;
    onLoadMore: () => void;
    className?: string;
}

export default function LoadMoreButton({ isLoading, hasMore, onLoadMore, className = "" }: LoadMoreButtonProps) {
    return (
        <div 
            className={`pt-[17px] flex justify-center flex-col items-center cursor-pointer ${
                isLoading ? 'animate-bounce' : 'hover:scale-105'
            } transition-all duration-300 ${className}`}
            onClick={onLoadMore}
        >
            <Direction
                color="#0099FE"
                className={`transition-all rotate-90 duration-300 ${
                    isLoading ? 'animate-spin' : 
                    !hasMore ? 'rotate-[270deg]' : ''
                }`} 
            />
            <span className="text-[#0099FE] text-[24px]">
                {isLoading ? 'LOADING...' : !hasMore ? 'SHOW LESS' : 'LOAD MORE'}
            </span>
        </div>
    );
}