import * as React from "react";
import { useState } from "react";

interface MintButtonType {
    className: string;
    price: number; 
    tonPrice: number;
    isLoading: boolean;
    onClick: () => void;
}

export default function MintButton({ className, price, tonPrice, isLoading, onClick }: MintButtonType) {
    const [isDisabled, setIsDisabled] = useState(false);

    const handleClick = () => {
        setIsDisabled(true);
        onClick();
    };

    return (
        <div 
            className={`${className} relative ${(isDisabled || isLoading) ? 'cursor-not-allowed opacity-50' : 'cursor-pointer hover:scale-105 transition-all duration-300'}`}
            onClick={(!isDisabled && !isLoading) ? handleClick : undefined}
        >
            <svg className="w-full" width="196" height="125" viewBox="0 0 196 125" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M7.99996 0H187.954V7.6179H195.542V117.382H187.954V125H7.99996V117.382H0.412292V7.6179H7.99996V0Z" 
                    fill={!isDisabled ? "#6DC5FF" : "#494949"}/>
                <path fillRule="evenodd" clipRule="evenodd" d="M187.954 3.41597C187.954 5.95527 187.924 8.46433 187.924 11.0036H195.542V117.382H187.954V125H11.6179V117.382C9.0786 117.382 6.5393 117.382 4 117.382V11.0036H11.6179V3.41597L187.954 3.41597Z" 
                    fill={!isDisabled ? "#0099FE" : "#494949"}/>
            </svg>

            <div className="absolute w-full top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%] flex flex-col items-center justify-center">
                {isLoading ? (
                    <>
                        <svg className="animate-spin h-8 w-8 text-white mb-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        <span className="text-[24px] text-white">MINTING...</span>
                    </>
                ) : (
                    <>
                        <span className={`text-[30px] line-height-1 ${isDisabled ? 'text-[#737373]' : 'text-white'}`}>MINT NFT</span>
                        <span className={`text-[24px] line-height-1 ${isDisabled ? 'text-[#737373]' : 'text-white'}`}>FOR</span>
                        <span className={`text-[24px] line-height-1 text-center ${isDisabled ? 'text-[#737373]' : 'text-white'}`}>{price} TON</span>
                        <span className={`text-[16px] opacity-[53%] line-height-1 text-center ${isDisabled ? 'text-[#737373]' : 'text-white'}`}>~{tonPrice} $</span>
                    </>
                )}
            </div>
        </div>
    )
}

