import * as React from "react";
import { OwnerProvider } from "@/context/OwnerContext";
import OwnerCollection from "./owner-collection";
import OwnerEditView from "./owner-edit-view";
import OwnerNft from "./owner-nft";
import { exampleOwnerData } from "@/data/owner";
import { OwnerData } from "@/types";
import OwnerReferral from "./owner-referral";

export default function Owner({ ownerData }: { ownerData: OwnerData | null }) {
  console.log(ownerData);
  return (
    <OwnerProvider>
      <div className="flex flex-col min-h-screen bg-black w-full overflow-x-hidden">
        <OwnerEditView ownerDatas={ownerData} />
        <OwnerCollection
          collectionData={ownerData?.collections || []}
        />
        <OwnerNft
          itemsData={ownerData?.items || []}
        />
        <OwnerReferral />
      </div>
    </OwnerProvider>
  )
}