import * as React from "react";
import { nftData } from "@/data/nft-data";
import OwnerNFTCard from "@/components/atomics/owner-nft-card";
import { useNavigate, useParams } from "react-router-dom";
import { OwnerNftData } from "@/types";
import { useOwner } from "@/context/OwnerContext";
import LoadMoreButton from "@/components/atomics/load-more-button";
import { useEffect } from "react";
import { useWallet } from "@/context/WalletContext";
import CollapsibleGroup from "@/components/atomics/collapsible-group";
interface OwnerNftType {
    itemsData: OwnerNftData[];
}

export default function OwnerNft({ itemsData }: OwnerNftType) {
    const navigate = useNavigate();
    const { isEditView, isOwnerView } = useOwner();
    const { walletAddress } = useWallet();
    const { address } = useParams();
    const [displayCount, setDisplayCount] = React.useState(8);
    const [isLoading, setIsLoading] = React.useState(false);
    const [showOwnerView, setShowOwnerView] = React.useState(false);

    const handleLoadMore = () => {
        setIsLoading(true);
        setTimeout(() => {
            if (displayCount <= itemsData.length) {
                setDisplayCount(8);
            } else {
                setDisplayCount(prev => Math.min(prev + 4, itemsData.length));
            }
            setIsLoading(false);
        }, 300);
    };

    useEffect(() => {
        if (walletAddress) {
            if (walletAddress === address) {
                setShowOwnerView(true);
            } else {
                setShowOwnerView(false);
            }
        }
    }, [walletAddress, address]);

    return (
        <CollapsibleGroup
            isEditView={isEditView}
            editTitle={isOwnerView && showOwnerView ? "MY NFT" : "WALLET NFT"}
            viewTitle={isOwnerView && showOwnerView ? "MY NFT" : "WALLET NFT"}
            className="w-full px-[30px] text-[30px]"
            extendedViewClassName="w-full px-[30px] py-[15px] text-[30px] bg-black text-white"
            collapsedViewClassName="w-full px-[30px] py-[15px] text-[30px] bg-black text-white"
        >
            <div className="flex flex-col px-[30px]">
                <div className="w-full grid grid-cols-4 gap-[18px] transition-all duration-500 ease-in-out">
                    {itemsData.slice(0, displayCount).map((item, index) => (
                        <OwnerNFTCard
                            key={index}
                            className={`w-full flex-shrink-0 transition-all duration-300 ease-in-out ${isLoading ? 'animate-pulse opacity-60' : 'opacity-100'
                                }`}
                            onClick={() => navigate(`/nft/${item.address}`)}
                            item={item}
                        />
                    ))}
                </div>
                {itemsData.length > 8 && (
                    <LoadMoreButton
                        isLoading={isLoading}
                        hasMore={displayCount < itemsData.length}
                        onLoadMore={handleLoadMore}
                    />
                )}
            </div>
        </CollapsibleGroup>
    )
}