import * as React from 'react';
import { createContext, useContext, ReactNode, useState } from 'react';

interface OwnerContextType {
  isEditView: boolean;
  setIsEditView: (value: boolean) => void;
  isOwnerView: boolean;
  setIsOwnerView: (value: boolean) => void;
}

const OwnerContext = createContext<OwnerContextType | undefined>(undefined);

export function OwnerProvider({ children }: { children: ReactNode }) {
  const [isEditView, setIsEditView] = useState(false);
  const [isOwnerView, setIsOwnerView] = useState(true);

  return (
    <OwnerContext.Provider value={{ isEditView, setIsEditView, isOwnerView, setIsOwnerView }}>
      {children}
    </OwnerContext.Provider>
  );
}

export function useOwner() {
  const context = useContext(OwnerContext);
  if (context === undefined) {
    throw new Error('useOwner must be used within an OwnerProvider');
  }
  return context;
}