import * as React from "react";
import { useState } from "react";
import { MenuType } from "@/types/leader-board";
import CircleBg from "@/components/atomics/circle-bg";

interface LeaderBoardItemProps {
    rank: number;
    name: string;
    value: number;
    address: string;
    avatar?: string;
    selectedMenu: MenuType;
    onClick?: () => void;
}

export default function LeaderBoardItem({ rank, name, value, address, avatar, selectedMenu, onClick }: LeaderBoardItemProps) {
    const [isHovered, setIsHovered] = useState(false);

    const formatValue = (value: number): string => {
        if (selectedMenu === 'sales quantity') {
            return value.toString();
        }
        return value.toFixed(2);
    };

    return (
        <div
            key={rank}
            className="flex items-center justify-between px-[20px] py-[15px] hover:bg-[#2B2B2B] cursor-pointer"
            onClick={onClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div className="flex items-center gap-[15px]">
                <div className="relative w-[66px] h-[66px]">
                    <CircleBg
                        className="absolute z-[2] w-[66px] h-[66px] top-[50%] translate-y-[-50%] translate-x-[-50%] left-[50%] rounded-full"
                        color={isHovered ? "#2B2B2B" : "#000000"}
                    />
                    <div className="absolute top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%] w-[60px] h-[62px]">
                        <img
                            src={avatar || '/assets/default-avatar.png'}
                            alt={name}
                            className="rounded-full"
                        />
                    </div>
                </div>

                <div className="flex flex-col">
                    <span className="text-[20px]">#{rank}</span>
                    <span className="text-[20px]">{name}</span>
                </div>
            </div>
            <div className="text-[20px] --blue flex flex-col items-center">
                <span>
                    {formatValue(value)}
                </span>
                <span>
                    {selectedMenu === 'sales quantity' ? 'NFTs' : 'TON'}
                </span>
            </div>
        </div>
    )
}


