import * as React from "react";

interface InviteFriendButtonProps {
    className?: string;
    color?: string;
    onClick?: () => void;
}

export default function InviteFriendButton({ className, color, onClick }: InviteFriendButtonProps) {
    return (
        <div className={className} onClick={onClick}>
            <svg width="23" height="34" viewBox="0 0 23 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.59745 27.2011H9.89617V30.603H6.59745H3.29872H0V27.2011V23.7992V20.3974H3.29872V16.9955H6.59745V13.6075V10.2056V6.80375V3.40188V0H9.81442H9.89617H13.1131H16.4119V3.40188H19.7106V6.80375V10.2056V13.6075H16.4119V17.0094H19.7106V20.4113H16.4119V17.0094H13.1131H9.89617H9.81442H6.59745V20.3974H3.29872V23.7992V27.2011H6.59745ZM6.59745 13.6075H9.81442H9.89617H13.1131H16.4119V10.2056V6.80375V3.40188H13.1131H9.89617H9.81442H6.59745H3.29872V6.80375V10.2056V13.6075H6.59745ZM19.7106 30.5981H23V27.1962H19.7106V23.7944H16.4119V27.1962H13.1131V30.5981H16.4119V34H19.7106V30.5981Z" fill={color || '#0099FE'}/>
            </svg>
        </div>
    )
}