import React from 'react';

interface ReportButtonProps {
  className?: string;
  walletAddress?: string | null;
}

export default function ReportButton({
  className = '',
  walletAddress,
}: ReportButtonProps) {
  const handleShare = () => {
    if (!walletAddress) return;
  };

  return (
    <div className={className}>
      <div className="relative" onClick={handleShare}>
        <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.90927 0H10.3357H10.3533H13.7797H17.2237H20.6678H24.1118V3.45258H27.556V6.90527H31V10.3578V13.7994V13.8104V17.252H30.9789V20.6422V24.0947H27.5349V27.5474H24.0907V31H20.6467H17.2026H13.7586H10.3322H10.3146H6.88819V27.5474H3.44404V24.0947H0V20.6422H0.0210865V17.252V17.1897V13.8104V13.7994V10.3578V6.90527H3.46512V3.45258H6.90927V0ZM13.7586 10.2845V6.83196H17.2026V10.2845V13.7371V17.1897H13.7586V13.7371V10.2845ZM17.2026 24.0948H13.7586V20.6423H17.2026V24.0948ZM13.7586 27.5474H17.2026H20.6467H24.0907V24.0947H27.5349V20.6422V17.1896H27.556V13.8104V13.7994V10.3578V6.90527H24.1118V3.45258H20.6678H17.2237H13.7797H10.3533H10.3357H6.90927V6.90527H3.46512V10.3578V13.7994V13.8104V17.1897V17.252V20.6423H3.44404V24.0947H6.88819V27.5474H10.3146H10.3322H13.7586Z" fill="#0099FE" />
        </svg>
      </div>
    </div>
  );
}