import * as React from "react";
import CheckIcon from "./check-icon";

interface AgreementType {
    className: string;
    onClick: () => void;
    isAgreed: boolean;
    isLoading: boolean;
}

export default function Agreement({ className, onClick, isAgreed, isLoading }: AgreementType) {
    return (
        <div className={className}>
            <p className="flex items-center gap-[5px] pb-[10px]">
                <span className={`text-[20px] ${isLoading ? 'text-[#2B2B2B]' : 'text-[#FE0000]'}`}>*</span>
                <span className={`text-[23px] ${isLoading ? 'text-[#2B2B2B]' : 'text-white'}`}>TERMS OF AGREEMENT</span>
            </p>
            <div className={`${isLoading ? 'pointer-events-none cursor-not-allowed' : ''} relative flex items-center justify-center gap-[40px]`}>
                <div className={`relative w-[24px] h-[24px] cursor-pointer  ${isAgreed ? 'bg-[#3B3B3B]' : 'bg-white'}`} onClick={onClick}>
                </div>
                {isAgreed && <CheckIcon className="absolute left-[0px] top-[8px] w-[40px] h-[28px]" color={isLoading ? '#2B2B2B' : '#0099FE'} onClick={onClick}/>}
                <div className="w-[calc(100%-64px)]">
                    <span className={`text-[20px] font-spline-sans-mono ${isLoading ? 'text-[#2B2B2B]' : ''}`}>
                        I agree to the  <a className={`${isLoading ? 'text-[#2B2B2B]' : 'text-[#0099FE]'} underline`}>terms </a> and <a className={`${isLoading ? 'text-[#2B2B2B]' : 'text-[#0099FE]'} underline`}>conditions</a>
                    </span>
                </div>
            </div>
        </div>
    )
}