import * as React from "react";

interface ShareButtonProps {
    className?: string;
    color?: string;
    onClick?: () => void;
}

export default function ShareButton({ className, color, onClick }: ShareButtonProps) {
    return (
        <div className={className} onClick={onClick}>
            <svg width="24" height="34" viewBox="0 0 24 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 27.2V30.6V34H3.42857H6.85714H10.2857H13.7143H17.1429H20.5714H24V30.6V27.2V23.8V20.4V17V13.6H20.5714H17.1429V17H20.5714V20.4V23.8V27.2V30.6H17.1429H13.7143H10.2857H6.85714H3.42857V27.2V23.8V20.4V17H6.85714V13.6H3.42857H0V17V20.4V23.8V27.2ZM3.42857 6.8H6.85714V3.4H10.2857V0H13.7143V3.4H17.1429V6.8H20.5714V10.2H17.1429V6.8H13.7143V10.2V13.6V17V20.4V23.8H10.2857V20.4V17V13.6V10.2V6.8H6.85714V10.2H3.42857V6.8Z" fill={color || '#565656'}/>
            </svg>
        </div>
    )
}