import React from "react";

interface VersionProps {
  version: string;
}

function Version({ version }: VersionProps) {
  return (
    <div className="fixed bottom-0 left-[10px] text-[#0099FE] text-[15px]">
      <span className="">{version}</span>
    </div>
  );
}

export default Version;
