import * as React from "react";
interface PictureAvatarType {
    className: string;
    image: string;
}
export default function PictureAvatar({ className, image }: PictureAvatarType) {
    return (
       <div className={`${className} relative aspect-square`}>
        <img src="/assets/mint-avatar-square-bg.png" alt="avatar" />
        <div className="absolute w-[calc(100%-17px)] aspect-square z-[4] top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
            <img src="/assets/avatar-bg.png" alt="avatar" />
        </div>
        <div className="absolute w-[calc(100%-18px)] aspect-square z-index-[3] top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
            <img src={image ? image : "/assets/avatar.png"} alt="avatar" />
        </div>
       </div>
    )
}