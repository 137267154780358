import * as React from "react";

export default function CircleBg({
  className,
  color = "#000000",
  onClick
}: {
  className?: string,
  color?: string,
  onClick?: (event: React.MouseEvent) => void
}) {
   return (
      <div 
        className={`${className} flex items-center justify-center`}
        onClick={onClick}
      >
            <svg version="1.1"
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 489 490"
                className="w-full h-full">
                <path d="M0 0 C9.57 0 19.14 0 29 0 C29 48.18 29 96.36 29 146 C19.76 146 10.52 146 1 146 C1 164.48 1 182.96 1 202 C-7.91 202 -16.82 202 -26 202 C-26 211.9 -26 221.8 -26 232 C-35.24 232 -44.48 232 -54 232 C-54 240.58 -54 249.16 -54 258 C-63.9 258 -73.8 258 -84 258 C-84 267.9 -84 277.8 -84 288 C-103.14 288 -122.28 288 -142 288 C-142 297.57 -142 307.14 -142 317 C-190.84 317 -239.68 317 -290 317 C-290 307.43 -290 297.86 -290 288 C-308.15 288 -326.3 288 -345 288 C-345 278.43 -345 268.86 -345 259 C-326.19 259 -307.38 259 -288 259 C-288 268.24 -288 277.48 -288 287 C-240.15 287 -192.3 287 -143 287 C-143 277.76 -143 268.52 -143 259 C-123.86 259 -104.72 259 -85 259 C-85 249.76 -85 240.52 -85 231 C-75.43 231 -65.86 231 -56 231 C-56 221.43 -56 211.86 -56 202 C-46.43 202 -36.86 202 -27 202 C-27 183.19 -27 164.38 -27 145 C-18.09 145 -9.18 145 0 145 C0 97.15 0 49.3 0 0 Z " fill={color} transform="translate(460,173)"/>
                <path d="M0 0 C9.57 0 19.14 0 29 0 C29 18.81 29 37.62 29 57 C19.43 57 9.86 57 0 57 C0 105.18 0 153.36 0 203 C-9.57 203 -19.14 203 -29 203 C-29 154.49 -29 105.98 -29 56 C-19.43 56 -9.86 56 0 56 C0 37.52 0 19.04 0 0 Z " fill={color} transform="translate(29,115)"/>
                <path d="M0 0 C47.19 0 94.38 0 143 0 C143 9.57 143 19.14 143 29 C95.81 29 48.62 29 0 29 C0 19.43 0 9.86 0 0 Z " fill={color} transform="translate(173,0)"/>
                <path d="M0 0 C8.91 0 17.82 0 27 0 C27 18.48 27 36.96 27 56 C36.57 56 46.14 56 56 56 C56 65.57 56 75.14 56 85 C65.57 85 75.14 85 85 85 C85 94.57 85 104.14 85 114 C75.1 114 65.2 114 55 114 C55 103.77 55 93.54 55 83 C45.76 83 36.52 83 27 83 C27 74.42 27 65.84 27 57 C18.09 57 9.18 57 0 57 C0 38.19 0 19.38 0 0 Z " fill={color} transform="translate(30,318)"/>
                <path d="M0 0 C9.57 0 19.14 0 29 0 C29 19.14 29 38.28 29 58 C19.43 58 9.86 58 0 58 C0 38.86 0 19.72 0 0 Z " fill={color} transform="translate(431,115)"/>
                <path d="M0 0 C19.14 0 38.28 0 58 0 C58 9.24 58 18.48 58 28 C38.86 28 19.72 28 0 28 C0 18.76 0 9.52 0 0 Z " fill={color} transform="translate(316,29)"/>
                <path d="M0 0 C19.14 0 38.28 0 58 0 C58 9.24 58 18.48 58 28 C38.86 28 19.72 28 0 28 C0 18.76 0 9.52 0 0 Z " fill={color} transform="translate(115,29)"/>
                <path d="M0 0 C9.57 0 19.14 0 29 0 C29 9.57 29 19.14 29 29 C19.43 29 9.86 29 0 29 C0 19.43 0 9.86 0 0 Z " fill={color} transform="translate(374,57)"/>
                <path d="M0 0 C9.24 0 18.48 0 28 0 C28 9.9 28 19.8 28 30 C18.76 30 9.52 30 0 30 C0 20.1 0 10.2 0 0 Z " fill={color} transform="translate(87,57)"/>
                <path d="M0 0 C9.57 0 19.14 0 29 0 C29 9.24 29 18.48 29 28 C19.43 28 9.86 28 0 28 C0 18.76 0 9.52 0 0 Z " fill={color} transform="translate(58,87)"/>
                <path d="M0 0 C9.24 0 18.48 0 28 0 C28 9.57 28 19.14 28 29 C18.76 29 9.52 29 0 29 C0 19.43 0 9.86 0 0 Z " fill={color} transform="translate(403,86)"/>
            </svg>
        </div>
    )
}