import * as React from "react";

interface PlusButtonProps {
    className?: string;
    color?: string;
    onClick?: () => void;
}

export default function PlusButton({ className, color, onClick }: PlusButtonProps) {
    return (
        <div className={className} onClick={onClick}>
            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="10" width="23" height="3" fill={color || "#0099FE"}/>
                <rect x="10" y="23" width="23" height="3" transform="rotate(-90 10 23)" fill={color || "#0099FE"}/>  
            </svg>
        </div>
    )
}