import React from "react";
import { CollectionMasterDataType, OwnerNftData } from "@/types";
import { useEffect, useState } from "react";
import { fetchData, CollectionDataCache } from "@/types/collection";
import { backend } from "@/services/backend";
interface NFTMetadata {
    onClick?: () => void;
    name: string;
    description: string;
    image: string;
}

interface OwnerNFTCardProps {
    className?: string;
    item: OwnerNftData;
    onClick?: () => void;
}

interface NFTState {
    metadata: NFTMetadata | null;
    collectionMasterData: CollectionMasterDataType | null;
    isLoading: boolean;
}


const OwnerNFTCard = ({ className, item, onClick }: OwnerNFTCardProps) => {
    const [nftState, setNftState] = useState<NFTState>({
        metadata: null,
        collectionMasterData: null,
        isLoading: true
    });

    useEffect(() => {
        const fetchNFTData = async () => {
            setNftState(prev => ({ ...prev, isLoading: true }));
            try {
                const [metadataData, collectionMasterData] = await Promise.all([
                    fetchData(item.data_url),
                    backend.getCollectionMasterData(item.collection)
                ]);   
                setNftState({
                    metadata: metadataData,
                    collectionMasterData,
                    isLoading: false
                });
            } catch (error) {
                console.error('Error fetching NFT data:', error);
                setNftState(prev => ({ ...prev, isLoading: false }));
            }
        };

        fetchNFTData();
    }, [item]);


    return (
        <div className={`${className} flex flex-col items-center justify-center`} onClick={onClick}>
            {nftState.isLoading ? (
                <div className="w-full aspect-square flex items-center justify-center">
                    <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-[#0099FE]"></div>
                </div>
            ) : (
                <>
                    <img src={nftState.metadata?.image || ''} className="w-full aspect-square" />
                    <div className="flex flex-col w-full">
                        <div className="flex justify-between">
                            {nftState.metadata?.name && nftState.metadata.name.length > 7 ? (
                                <>
                                    <p className="text-[12px] w-[calc(100%-10px)] truncate">{nftState.metadata.name.slice(0, -3)}</p>
                                    <p className="text-[12px]">{nftState.metadata.name.slice(-3)}</p>
                                </>
                            ) : (
                                <p className="text-[12px]">{nftState.metadata?.name}</p>
                            )}
                        </div>
                        <p className="text-[12px]">#{item.index_in_collection}</p>
                    </div>
                    <div>
                        <p className="text-[12px]">MINTED FOR:</p>
                        <p className="text-[12px] text-[#0099FE] font-spline-sans-mono">
                            {Number(nftState.collectionMasterData?.price)/1000000000} ton
                        </p>
                    </div>
                </>
            )}
        </div>
    )
}

export default OwnerNFTCard;